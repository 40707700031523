import getData from "@/__main__/get-data.mjs";
import { getRitoLanguageCodeFromBCP47 } from "@/app/util.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import LolArenaAugments from "@/game-lol/models/lol-arena-augments.mjs";
import LolArenaStatsPatch from "@/game-lol/models/lol-arena-stats-patch.mjs";
import LoLChampionArenaStats from "@/game-lol/models/lol-champion-arena.mjs";
import LolChampionInsights from "@/game-lol/models/lol-champion-insights.mjs";
import LoLChampionStatsWithMatchups from "@/game-lol/models/lol-champion-stats-matchups.mjs";
import LoLChampionStatsTrends from "@/game-lol/models/lol-champion-stats-trends.mjs";
import LoLChampionProMatches from "@/game-lol/models/lol-probuild-match.mjs";
import LoLRole from "@/game-lol/models/lol-roles.mjs";
import { getKeyForArenaChampionData } from "@/game-lol/utils/arena-queue-utils.mjs";
import {
  getChampionKeyById,
  getCurrentPatchForStaticData,
  getDefaultedFiltersForChampion,
  getPrereleaseChampionByKey,
  getSearchParamsForChampion,
  getStaticChampionByKey,
} from "@/game-lol/utils/util.mjs";
import { NotFoundError } from "@/util/custom-errors.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

// path regExp: /lol/champions/:championKey/:build|:arena|:aram|:probuilds|:trends|:counters/:matchupChampionKey
async function fetchData(params, searchParams, state) {
  const [championKeyOrId, tab, matchupChampionKey] = params;
  const probuildPage = state?.page || 1;

  const lang = getLocale();
  const ritoLanguageCode = getRitoLanguageCodeFromBCP47(lang);

  // get patch
  const patch = getCurrentPatchForStaticData();
  const championKey = getChampionKeyById(championKeyOrId) || championKeyOrId;
  const champion =
    getStaticChampionByKey(championKey, patch) ||
    getPrereleaseChampionByKey(championKey);
  const matchupChampion = getStaticChampionByKey(matchupChampionKey, patch);

  // Used for splash pages before a champion comes out
  const isPreReleaseChampion = champion?.prerelease;

  // validate the params
  if (!champion) {
    throw new NotFoundError(`Champion "${championKey}" Not Found`);
  }

  if (isPreReleaseChampion) return;

  const championId =
    typeof champion.id === "string"
      ? Number.parseInt(champion.id)
      : champion.id;
  const matchupChampionId =
    typeof matchupChampion?.id === "string"
      ? Number.parseInt(matchupChampion.id)
      : null;

  const roleParam = searchParams?.get("role");

  let defaultRole = null;

  try {
    defaultRole =
      !roleParam &&
      (await getData(API.getChampionRole(championId), LoLRole, [
        "lol",
        "championRoles",
        championId,
      ]));
  } catch (e) {
    // nothing to do here really
  }

  const filters = getDefaultedFiltersForChampion(
    searchParams,
    roleParam || defaultRole?.primaryRole,
    tab,
  );
  const urlParams = getSearchParamsForChampion(filters);

  // Single champion stats
  const getChampionStats = (championId) => {
    return !filters.comingSoon
      ? getData(
          API.getChampionStats(championId, filters, true),
          LoLChampionStatsWithMatchups,
          ["lol", "championPage", championId, urlParams.toString()],
        )
      : new Promise((resolve) => {
          resolve();
        });
  };

  // Single champion stats trends
  const getChampionStatsTrends = (championId) => {
    return !filters.comingSoon
      ? getData(
          API.getChampionTrends(championId, filters),
          LoLChampionStatsTrends,
          ["lol", "championStatsTrends", championId, urlParams.toString()],
        )
      : new Promise((resolve) => {
          resolve();
        });
  };

  // Single champion builds
  const getChampionBuilds = (championId, opponentChampionId) => {
    const path = !opponentChampionId
      ? [
          "lol",
          "championBuilds",
          championId,
          filters.region,
          filters.queue,
          filters.role,
          null,
        ]
      : [
          "lol",
          "championBuilds",
          championId,
          filters.region,
          filters.queue,
          filters.role,
          opponentChampionId,
        ];

    return !filters.comingSoon
      ? API.AGGREGATE.getChampionBuilds({
          params: {
            queue: filters.queue,
            role: filters.role,
            championId,
            opponentChampionId,
          },
          path,
        })
      : new Promise((resolve) => {
          resolve();
        });
  };

  // Single champion PRO builds
  const getChampionProMatches = (championId, count) => {
    return !filters.comingSoon
      ? getData(
          API.getChampionProMatches(championId, filters.role, count),
          LoLChampionProMatches,
          ["lol", "championProMatches", championId, filters.role],
        )
      : new Promise((resolve) => {
          resolve();
        });
  };

  const requests = [];

  switch (tab) {
    case "arena": {
      const { patch } = await getData(
        API.getArenaStatsPatch(),
        LolArenaStatsPatch,
        ["lol", "arenaStatsPatch"],
      );
      const lang = getRitoLanguageCodeFromBCP47(getLocale());
      const key = getKeyForArenaChampionData(patch, championId);
      // const patchFull = getLatestPatchForStaticData()
      // const patch = prettyPatch(patchFull);
      requests.push(
        getData(
          API.getArenaChampionStats(patch, championId),
          LoLChampionArenaStats,
          ["lol", "championStats", key],
        ),
      );

      // Static data for augments
      requests.push(
        getData(API.getArenaAugments(patch, lang), LolArenaAugments, [
          "lol",
          "arenaAugments",
          patch,
          lang,
        ]),
      );
      break;
    }
    case "nexus-blitz": {
      requests.push(getChampionBuilds(championId));
      requests.push(
        getData(
          API.getChampionInsights(),
          LolChampionInsights,
          ["lol", "championTips", ritoLanguageCode],
          { shouldFetchIfPathExists: false },
        ),
      );
      break;
    }
    case "probuilds": {
      requests.push(getChampionProMatches(championId, probuildPage * 15));
      break;
    }
    case "urf":
    case "one-for-all": {
      requests.push(getChampionStats(championId));
      requests.push(getChampionBuilds(championId));
      requests.push(
        getData(
          API.getChampionInsights(),
          LolChampionInsights,
          ["lol", "championTips", ritoLanguageCode],
          { shouldFetchIfPathExists: false },
        ),
      );
      break;
    }
    case "trends": {
      requests.push(getChampionStats(championId));
      requests.push(getChampionStatsTrends(championId));
      break;
    }
    case undefined:
    case "build":
    default: {
      requests.push(getChampionStats(championId));
      requests.push(getChampionStatsTrends(championId));
      requests.push(getChampionBuilds(championId));
      requests.push(
        getData(
          API.getChampionInsights(),
          LolChampionInsights,
          ["lol", "championTips", ritoLanguageCode],
          { shouldFetchIfPathExists: false },
        ),
      );
      if (matchupChampionId) {
        requests.push(getChampionBuilds(championId, matchupChampionId));
      }
      break;
    }
  }

  return Promise.all(requests);
}

export default fetchData;
